import { Sync } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useToggle } from '@price-for-profit/react-hooks';
import { useEventStatusCreate, useEventTriggerAdfPipeline } from 'shared/mutations';
import { useGetPriceSyncInProgress } from 'shared/queries';
import { generatePriceSyncCorrelationId } from 'shared/utility';
import { PriceSyncInProgressDialog } from './price-sync-in-progress-dialog';

interface PriceSyncActionProps {
    user: drive.UserInfo;
}

export function PriceSyncAction({ user }: PriceSyncActionProps) {
    const triggerAdfMutation = useEventTriggerAdfPipeline();
    const createEventStatus = useEventStatusCreate();
    const priceSyncInProgress = useGetPriceSyncInProgress();
    const [isPriceSyncInProgressDialogOpened, togglePriceSyncInProgressDialog] = useToggle(false);

    const triggerPriceSync = async () => {
        const priceSyncCorrelationId = generatePriceSyncCorrelationId();

        await createEventStatus.mutateAsync({
            eventType: 'PriceSync',
            eventTrigger: 'PriceSyncStarted',
            actor: user.email,
            correlationId: priceSyncCorrelationId,
        });
        await triggerAdfMutation.mutateAsync({
            actor: user.id,
            eventType: 'application.event.triggered',
            subject: 'Build and Submit Batch Process - Price Sync',
            eventBody: { email: user.email, priceSyncCorrelationId },
            correlationId: priceSyncCorrelationId,
        });
    };

    return (
        <Box>
            <Button
                onClick={() => (priceSyncInProgress ? togglePriceSyncInProgressDialog() : triggerPriceSync())}
                startIcon={<Sync />}
                sx={{ mr: 1 }}
                size='small'
                variant='outlined'
            >
                {priceSyncInProgress ? 'In Progress' : 'Price Sync'}
            </Button>
            <PriceSyncInProgressDialog
                isDialogOpened={isPriceSyncInProgressDialogOpened}
                onDialogClose={togglePriceSyncInProgressDialog}
            />
        </Box>
    );
}
