export const QUERYKEY_CLIENT_USER_RESTRICTED = 'client-user-restricted';
export const QUERYKEY_COST = 'cost';
export const QUERYKEY_PRICE = 'price';
export const QUERYKEY_COST_WARNING_SUMMARY = 'cost-warning-summary';
export const QUERYKEY_PRICE_WARNING_SUMMARY = 'price-warning-summary';
export const QUERYKEY_EXCHANGE_RATES = 'exchange-rates';
export const QUERYKEY_SURCHARGE = 'surcharge';
export const QUERYKEY_SALES_OFFICE_OPTIONS = 'sales-office-options';
export const QUERYKEY_WARNING_CONFIGURATION = 'warning-configuration';
export const QUERYKEY_SUBMISSION_HISTORY_METADATA = 'submission-history-metadata';
export const QUERYKEY_MASS_ACTION_STATUS = 'mass-action-status';
export const QUERYKEY_MILL_SUPPLIER_OPTIONS = 'mill-supplier-options';
export const QUERYKEY_SURCHARGE_OPTIONS = 'surcharge-options';
export const QUERYKEY_SUBMISSION_HISTORY_AFTER_DATE = 'submission-history-after-date';
export const QUERYKEY_COST_FUTURE_RATE = 'cost-future-rates';
export const QUERYKEY_COST_FUTURE_RATE_METADATA = 'cost-future-rates-metadata';
export const QUERYKEY_PRICE_SYNC_STATUS = 'price-sync-status';
