import { StatusResponseData } from '@price-for-profit/micro-services';
import { setMassActionCorrelationId } from './mass-action-correlation-id';
import {
    BATCH_PROCESS_SUCCEEDED_STATUSES,
    MASS_ACTION_FAILED_STATUSES,
    PRICE_SYNC_FAILED_STATUSES,
} from 'shared/constants';
import { setPriceSyncCorrelationId } from './price-sync-correlation-id';

// TODO: Change the name of this function to be more specific to mass actions.
export function generateCorrelationId(): string {
    const massActionCorrelationId = crypto.randomUUID().replaceAll('-', '');
    setMassActionCorrelationId(massActionCorrelationId);
    return massActionCorrelationId;
}

export function generatePriceSyncCorrelationId(): string {
    const correlationId = crypto.randomUUID().replaceAll('-', '');
    setPriceSyncCorrelationId(correlationId);
    return correlationId;
}

export function isFinalizedStatus(data: StatusResponseData[] | undefined): boolean {
    return (
        typeof data?.find(s =>
            [
                ...BATCH_PROCESS_SUCCEEDED_STATUSES,
                ...MASS_ACTION_FAILED_STATUSES,
                ...PRICE_SYNC_FAILED_STATUSES,
            ].includes(s.eventTrigger)
        ) !== 'undefined'
    );
}

export function isSuccessStatus(data: StatusResponseData[] | undefined): boolean {
    return typeof data?.find(s => BATCH_PROCESS_SUCCEEDED_STATUSES.includes(s.eventTrigger)) !== 'undefined';
}
