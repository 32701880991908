import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface PriceSyncInProgressDialogProps {
    isDialogOpened: boolean;
    onDialogClose: () => void;
}

export function PriceSyncInProgressDialog({ isDialogOpened, onDialogClose }: PriceSyncInProgressDialogProps) {
    return (
        <Dialog onClose={() => onDialogClose()} open={isDialogOpened}>
            <DialogTitle sx={{ color: 'primary.main' }}>In Progress...</DialogTitle>
            <DialogContent>Price sync in progress, please continue waiting.</DialogContent>
            <DialogActions>
                <Button startIcon={<Close />} onClick={() => onDialogClose()}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
