import { useService } from '@price-for-profit/service-provider';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERYKEY_PRICE_SYNC_STATUS } from 'shared/constants';
import {
    getPriceSyncCorrelationId,
    isFinalizedStatus,
    isSuccessStatus,
    setPriceSyncCorrelationId,
} from 'shared/utility';

const SECOND = 1000;

export function useGetPriceSyncInProgress() {
    const { eventStatusService } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const correlationId = getPriceSyncCorrelationId() || '';
    const { data } = useQuery({
        queryKey: [QUERYKEY_PRICE_SYNC_STATUS, correlationId],
        queryFn: () => eventStatusService.getStatusByCorrelationId(correlationId),
        enabled: correlationId !== '',
        refetchInterval: query => {
            const data = query.state.data;

            if (isFinalizedStatus(data)) {
                if (isSuccessStatus(data)) {
                    enqueueSnackbar('Price sync completed successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar(`Price sync failed to complete. Correlation ID: ${correlationId}`, {
                        variant: 'error',
                    });
                }
                setPriceSyncCorrelationId('');
                return 0;
            }
            return SECOND * 10;
        },
    });

    return correlationId !== '' && !isFinalizedStatus(data);
}
