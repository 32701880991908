// export const CLIENT_ID = 'ryerson' as const;
// console.log('client: ', CLIENT_ID);

export const ROW_PER_PAGE_OPTIONS = [15, 25, 50, 100];

export const WARNING_BASE_COST_MAX_CHANGE = 'Base Cost Change % Greater Than Max Allowable Value.';
export const WARNING_BASE_COST_ADJUSTED = 'Base Cost Has Been Adjusted.';
export const WARNING_MARKET_MOVEMENT_MAX_CHANGE = 'Market Movement Change $ Greater Than Max Allowable Value.';
export const WARNING_PENDING_FUTURE_EFFECTIVE_DATE_ASSOCIATED =
    'There is a pending future effective (date) change associated with this record.';

export const WARNING_TARGET_MARGIN_NEW_BELOW_FLOOR_MARGIN_NEW = 'Target Margin % (New) is below Floor Margin % (New).';

export const MASS_ACTION_THRESHOLD_WARNING = 100;
export const MASS_ACTION_PRICE_MASTER_TOOLTIP = `
    ● Ability to apply the same change across multiple lines quickly:
    ● Field: Which field are we applying this adjustment to? options are
    º “Target Margin (New)”
    º “Book Price (New)”
    º “Floor Price (New)”
    º “Target Margin % (New) to Current”
    º “Book Price (New) to Current”
    º “Floor Price (New) to Current”
    ● Mode:
    º Complete - overwrite current value completely
    º Incremental - add specified value to the current value
`;
export const MASS_ACTION_COST_MASTER_TOOLTIP = `
    ● Ability to apply the same change across multiple lines quickly:
    ● Field: Which field are we applying this adjustment to? options are “Base Cost (New)”, and “Market Movement (New)”
    ● Mode:
    º Complete - overwrite current value completely
    º Incremental - add specified value to the current value
    ● Hold Price: Hold price when applying these changes? Yes or No.

`;
export const MASS_ACTION_FIELD_TOOLTIP = 'Which field are we applying this adjustment to?';
export const MASS_ACTION_MODE_TOOLTIP =
    '● Complete - overwrite current value completely. \n ● Incremental - add specified value to the current value.';
export const MASS_ACTION_INPUT_TOOLTIP = 'Which field are we applying this adjustment to?';
export const MASS_ACTION_HOLD_PRICE_TOOLTIP = 'Hold price when applying these changes?';

export const BATCH_PROCESS_SUCCEEDED_STATUSES = ['IngestBatchProcessSuccess'];
export const MASS_ACTION_FAILED_STATUSES = ['MassActionFailed', 'BatchJobFailed', 'IngestBatchProcessFailed'];
export const PRICE_SYNC_FAILED_STATUSES = ['PriceSyncFailed', 'BatchJobProcessingFailure'];

export const PERFORMANCE_METRICS_FOOTER =
    'Metrics calculated using Transactional data, including Ecommerce and Ecoin and excluding Fab, where quote line weight is between 50 and 5,000 lbs';
